import React from 'react'
import Link from 'next/link'
import moment from 'moment'
import UnsplashImage from '../../shared/UnsplashImage'

interface ChallengeSpaceCardProps {
	space: any
	subject: any
	organisation: any
	key?: any
}

class ChallengeSpaceCard extends React.Component<
	ChallengeSpaceCardProps,
	{ unsplashImg: any }
> {
	constructor(props: ChallengeSpaceCardProps) {
		super(props)

		this.state = {
			unsplashImg: null,
		}
	}

	render() {
		const { subject, organisation, space } = this.props

		return (
			<div className="bg-white text-left text-black w-full max-w-4xl mx-auto rounded-lg overflow-hidden text-start shadow-lg leading-relaxed h-full flex flex-col">
				<Link
					href={'/challenge-spaces/[challengeSpaceId]'}
					as={`/challenge-spaces/${space.id}`}
					passHref
				>
					<div className="relative w-full flex justify-end items-start flex-col h-48 cursor-pointer">
						<UnsplashImage
							{...(space.attributes.unsplash ?? {})}
							title={space.attributes.title}
							width="600"
							height="400"
						/>

						<div className="bg-black bg-opacity-25 w-full h-full z-10 absolute top-0 left-0 right-0 bottom-0"></div>
						{subject && (
							<span className="text-sm whitespace-nowrap rounded-full inline-block -mb-3 mx-3 bg-purple-900 text-white relative z-10 px-3 py-1 font-semibold">
								{subject?.attributes?.title}
							</span>
						)}
					</div>
				</Link>
				<Link
					href={'/challenge-spaces/[challengeSpaceId]'}
					as={`/challenge-spaces/${space.id}`}
					passHref
				>
					<h2 className="text-xl font-semibold cursor-pointer text-gray-900 mb-2 px-3 pt-6">
						{space.attributes.title}
					</h2>
				</Link>

				<div className="px-3 flex flex-col items-start mb-4">
					{space.attributes.useChallenges && (
						<span className="text-sm whitespace-nowrap text-purple-900 uppercase">
							Period:{' '}
							{moment(space.attributes.challengeStartDate).format('DD-MM-YYYY')}{' '}
							- {moment(space.attributes.challengeEndDate).format('DD-MM-YYYY')}
						</span>
					)}
					{organisation && (
						<Link
							href={'/challenge-spaces/organisation/[slug]'}
							as={`/challenge-spaces/organisation/${organisation.attributes.slug}`}
							passHref
						>
							<span className="mt-2 text-purple-800 font-bold text-sm cursor-pointer hover:text-purple-700 transition-colors duration-300">
								{organisation.attributes.name}
							</span>
						</Link>
					)}
				</div>

				{space.attributes.summary && (
					<p className="text-gray-700 mb-4 px-3">{space.attributes.summary}</p>
				)}

				<div className={'flex flex-col items-stretch pb-6 px-2 mt-auto'}>
					<Link
						href={'/challenge-spaces/[challengeSpaceId]'}
						as={`/challenge-spaces/${space.id}`}
						passHref
					>
						<button
							className={
								'bg-white text-gray-900 hover:text-black hover:bg-white border hover:border-gray-900 transition-colors duration-300 border-gray-300 mx-2 my-2 z-10 relative'
							}
						>
							More information
						</button>
					</Link>
					{!space.attributes.submissionExpired &&
					space.attributes.useChallenges ? (
						<>
							<Link
								href={
									'/challenge-spaces/[challengeSpaceId]?action=submitChallenge'
								}
								as={`/challenge-spaces/${space.id}?action=submitChallenge`}
								passHref
							>
								<button
									className={
										'bg-yellow-300 transition-colors duration-300 text-gray-900 hover:bg-yellow-200 mx-2 z-10 relative my-2'
									}
								>
									Submit challenge
								</button>
							</Link>
							{space.attributes.challengeSubmitDeadline && (
								<span className="text-xs px-2 text-center text-gray-800">
									Submission deadline:{' '}
									<strong>
										{moment(space.attributes.challengeSubmitDeadline).format(
											'DD-MM-YYYY'
										)}
									</strong>
								</span>
							)}
						</>
					) : (
						space.attributes.useChallenges && (
							<span className="text-gray-700 self-center text-xs rounded-full px-3 py-1 bg-gray-100 font-semibold">
								Deadline has passed
							</span>
						)
					)}
				</div>
			</div>
		)
	}
}

export default ChallengeSpaceCard
