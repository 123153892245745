import ActiveLink from '../../shared/ActiveLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import {
	faBars,
	faTimes,
	faChevronDown,
} from '@fortawesome/pro-light-svg-icons'

const Header = ({ authenticated, extraClass = 'bg-white', fixed = true }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<header
			className={
				`header w-full z-30 shadow top-0 inset-x-0${fixed ? ' fixed' : ''} ` +
				extraClass
			}
		>
			<div className="container mx-auto py-4 px-4">
				<div className="flex flex-wrap items-center">
					<ActiveLink href="/" activeClassName="font-semibold">
						<a className="mr-auto block" style={{ maxWidth: '150px' }}>
							<img
								className="block"
								src="/static/img/logo_masterchallenge.svg"
								alt="Logo Masterchallenge"
							/>
						</a>
					</ActiveLink>

					<button
						className="block lg:hidden"
						onClick={() => setIsOpen(!isOpen)}
					>
						<FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
					</button>

					<nav className={isOpen ? 'block lg:block' : 'hidden lg:block'}>
						<ul className="mt-6 lg:mt-0 flex flex-wrap items-center md:justify-end font-sans font-normal tracking-normal border-t lg:border-0">
							<li className="block w-full lg:w-auto">
								<ActiveLink
									href="/challenge-spaces"
									activeClassName="text-purple-900"
								>
									<a className="py-3 px-3 block hover:font-semibold">
										Challenge spaces
									</a>
								</ActiveLink>
							</li>
							<li className="block w-full relative lg:w-auto submenu-parent">
								<a className="py-3 px-3 block hover:font-semibold cursor-pointer hover:text-purple-800">
									Tell me more{' '}
									<FontAwesomeIcon
										icon={faChevronDown}
										size="xs"
										className="hidden lg:inline"
									/>
								</a>

								<ul className="text-sm lg:text-base rounded-lg bg-gray-100 lg:bg-white lg:absolute p-3 z-10 lg:shadow-lg lg:hidden submenu">
									<li>
										<ActiveLink
											href="/for-educational-institutions"
											activeClassName="text-purple-900"
										>
											<a className="py-2 px-3 block hover:font-semibold hover:text-purple-900">
												For educational institutions
											</a>
										</ActiveLink>
									</li>
									<li>
										<ActiveLink
											href="/for-educators"
											activeClassName="text-purple-900"
										>
											<a className="py-2 px-3 block hover:font-semibold hover:text-purple-800">
												For educators
											</a>
										</ActiveLink>
									</li>
									<li>
										<ActiveLink
											href="/for-companies"
											activeClassName="text-purple-900"
										>
											<a className="py-2 px-3 block hover:font-semibold hover:text-purple-900">
												For companies
											</a>
										</ActiveLink>
									</li>
									<li>
										<a
											href="https://learn.masterchallenge.me/career-opportunities"
											target="_blank"
											rel="noreferrer"
										>
											<span className="py-2 px-3 block hover:font-semibold hover:text-purple-900">
												For students
											</span>
										</a>
									</li>
								</ul>
							</li>

							<li className="block w-full relative lg:w-auto submenu-parent">
								<ActiveLink href="/about-us" activeClassName="text-purple-900">
									<a className="py-3 px-3 block hover:font-semibold hover:text-purple-800">
										About us{' '}
										<FontAwesomeIcon
											icon={faChevronDown}
											size="xs"
											className="hidden lg:inline"
										/>
									</a>
								</ActiveLink>

								<ul className="text-sm lg:text-base rounded-lg bg-gray-100 lg:bg-white lg:absolute p-3 lg:shadow-lg mb-2 lg:mb-0 z-10 lg:hidden submenu">
									<li className="block w-full lg:w-auto">
										<ActiveLink
											href="/about-us"
											activeClassName="text-purple-900"
										>
											<a className="py-3 md:px-2 lg:px-3 block hover:font-semibold hover:text-purple-800">
												Team
											</a>
										</ActiveLink>
									</li>
									<li className="block w-full lg:w-auto">
										<ActiveLink
											href="/join-us"
											activeClassName="text-purple-900"
										>
											<a className="py-3 md:px-2 lg:px-3 block hover:font-semibold hover:text-purple-800">
												Jobs
											</a>
										</ActiveLink>
									</li>
									<li>
										<a
											className="py-3 md:px-2 lg:px-3 block hover:text-purple-900"
											href="https://blog.masterchallenge.me/"
											target="_blank"
											rel="noreferrer"
										>
											Blog
										</a>
									</li>
								</ul>
							</li>

							{!authenticated && (
								<li className="block w-full lg:w-auto ml-2">
									<ActiveLink href="/login" activeClassName="">
										<button className="bg-black text-white">Login</button>
									</ActiveLink>
								</li>
							)}

							{authenticated && (
								<li className="block w-full lg:w-auto lg:mr-2">
									<ActiveLink href="/logout" activeClassName="">
										<a className="py-3 md:px-2 lg:px-3 block hover:font-semibold">
											Logout
										</a>
									</ActiveLink>
								</li>
							)}

							{authenticated && (
								<li className="block w-full lg:w-auto">
									<ActiveLink href="/account" activeClassName="">
										<button className="bg-gray-900 transition-colors duration-300 hover:bg-gray-800 text-white">
											Dashboard
										</button>
									</ActiveLink>
								</li>
							)}
						</ul>
					</nav>
				</div>
			</div>
		</header>
	)
}

export default Header
